import { IS_APP } from "@/__main__/constants.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import router from "@/__main__/router.mjs";
import globals from "@/util/global-whitelist.mjs";

/**
 * Time after navigating to clear cache. This should always be longer than the
 * transition time (exit-transitions.mts) so that the previous page resources
 * can be marked as stale and cleared.
 */
const BATCH_TIME = 3000;
let t: Timeout;
const cleanup = [];

export function setup() {
  if (!IS_APP) return;

  const cb = () => {
    clearTimeout(t);
    t = setTimeout(async () => {
      globals.__BLITZ__.clearCache();
      const _ = await blitzMessage(EVENTS.APP_TRIM, null);
    }, BATCH_TIME);
  };
  cleanup.push(() => clearTimeout(t));

  router.events.on(router.events.EVENT_CHANGE_ROUTE_COMPONENT, cb);
  cleanup.push(() =>
    router.events.off(router.events.EVENT_CHANGE_ROUTE_COMPONENT, cb),
  );
}

export function teardown() {
  for (const c of cleanup) c();
  cleanup.length = 0;
}

declare global {
  interface BlitzMessaging {
    clearCache(): void;
  }
}
